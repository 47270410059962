h1 {
  @include tx-title-l;

  margin-bottom: 1.5em;
}

h2 {
  @include tx-title-m;

  margin-bottom: 1.5em;
}

.richtext {
  p {
    @include tx-body;

    margin-bottom: 1.2em;
  }

  a:not(.button) {
    display: inline-block;
    color: $primary;
    font-weight: 700;
    text-decoration: underline;
    transition: color 0.2s ease;

    &:hover {
      color: $primary-dark;
    }
  }
}
