@mixin cf {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin btn-base {
  appearance: none;
  position: relative;
  display: block;
  padding: 0;
  outline: none;
}

@mixin visuallyhidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
