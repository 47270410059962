.body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1 0 auto;
}

.site-footer {
  flex-shrink: 0;
}
