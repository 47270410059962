.checkbox {
  display: flex;
  align-items: center;
}

.checkbox__box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(20);
  height: rem(20);
  border-radius: 3px;
  background-color: $white;
  border: 1px solid $silver;
  transition: background-color 0.2s ease, border-color 0.2s ease;

  &::after {
    content: "";
    display: block;
    width: 13px;
    height: 10px;
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.707 1a1 1 0 010 1.414L5.414 9.707a1 1 0 01-1.414 0L.707 6.414A1 1 0 112.121 5l2.586 2.586L11.293 1a1 1 0 011.414 0z' fill='%23fff'/%3E%3C/svg%3E");
  }
}

.checkbox:hover,
.checkbox__input:focus + {
  .checkbox__box {
    border-color: $dark-grey;
  }
}

.checkbox__input:checked + .checkbox__box {
  background-color: $secondary;
  border-color: $secondary;
}

.checkbox__input:checked:focus + .checkbox__box,
.checkbox__input:checked:hover + .checkbox__box {
  background-color: $secondary-dark;
  border-color: $secondary-dark;
}

.checkbox__label {
  margin-top: rem(4);
  margin-left: rem(8);
}

// Modifiers
.checkbox--is-disabled {
  pointer-events: none;

  .checkbox__label {
    color: $medium-grey;
  }

  .checkbox__input:checked + .checkbox__box {
    background-color: $medium-grey;
    border-color: $medium-grey;
  }

  .checkbox__input + .checkbox__box {
    border-color: $medium-grey;
  }
}
