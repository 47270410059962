.svg-add-dims {
	width: 24px;
	height: 24px;
}

.svg-calendar-dims {
	width: 24px;
	height: 24px;
}

.svg-checkmark-large-dims {
	width: 32px;
	height: 32px;
}

.svg-close-dims {
	width: 24px;
	height: 24px;
}

.svg-delete-dims {
	width: 24px;
	height: 24px;
}

.svg-edit-dims {
	width: 24px;
	height: 24px;
}

.svg-help-dims {
	width: 24px;
	height: 24px;
}

.svg-printer-dims {
	width: 24px;
	height: 24px;
}

.svg-restore-dims {
	width: 24px;
	height: 24px;
}

.svg-share-dims {
	width: 24px;
	height: 24px;
}

