.loader {
  margin: rem(80) auto 0;
  text-align: center;

  > div {
    width: rem(14);
    height: rem(14);
    background-color: $silver;
    border-radius: 100%;
    display: inline-block;
    animation: loader-anim 1.4s infinite ease-in-out both;
  }

  .loader__bullet1 {
    animation-delay: -0.32s;
  }

  .loader__bullet2 {
    animation-delay: -0.16s;
  }
}
