html {
  text-size-adjust: 100%;

  // We assume browser default font size is 16px. Also set $base-font-size to 16px for correct rem calculations.
  font-size: 100%;
  box-sizing: border-box;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

html,
body {
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  color: $body-font-color;
  font-family: $body-font;
  line-height: $base-line-height;
}

a {
  text-decoration: none;
  color: inherit;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
