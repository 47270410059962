.visuallyhidden {
  @include visuallyhidden;
}

// Text sizes
.tx-title-l {
  @include tx-title-l;
}

.tx-title-m {
  @include tx-title-m;
}

.tx-title-s {
  @include tx-title-s;
}

.tx-title-xs {
  @include tx-title-xs;
}

.tx-body {
  @include tx-body;
}

.tx-body-s {
  @include tx-body-s;
}

.tx-caption {
  @include tx-caption;
}

// Text styles
.tx-bold {
  font-weight: 700 !important;
}

// Spacings
@each $name, $size in $spaces {
  .mb-#{$name} {
    margin-bottom: rem($size);
  }
}
