.icon-btn {
  @include btn-base;

  display: flex;
  align-items: center;
  border: none;
  background: none;
  color: $primary;

  .svg-icon {
    > svg {
      fill: $primary;
    }
  }
}

.icon-btn__label {
  margin-top: rem(3);
  margin-left: rem(8);
  vertical-align: middle;
  font-weight: bold;
}
