.confirmation {
  //
}

.confirmation__result {
  width: 100%;
  border: 0;
  border-collapse: collapse;

  td {
    vertical-align: baseline;
  }

  @include mobile {
    display: block;

    tr,
    td {
      display: block;
    }
  }
}

.confirmation__result-data {
  font-weight: 600;
  padding-bottom: rem(20);
}

.confirmation__cta {
  @include list-reset;

  display: flex;
  justify-content: flex-end;

  > li {
    margin-right: rem(20);

    &:last-child {
      margin-right: 0;
    }
  }

  @include mobile {
    display: block;

    > li {
      width: 100%;
      margin-right: 0;
      margin-bottom: map_get($spaces, m);

      .button {
        width: 100%;
      }
    }
  }
}
