.header {
  position: relative;
  border-bottom: 1px solid $light-grey;
  margin-bottom: rem(80);
}

.header__inner {
  display: flex;
  align-items: center;
  padding: rem(30) 0;

  @include mobile {
    padding: rem(24) 0;
  }
}

.header__logo {
  margin-right: auto;

  > img {
    display: block;
  }

  @include mobile {
    > img {
      height: rem(28);
    }
  }
}

.meta-nav {
  @include list-reset;
  @include cf;

  > li {
    float: left;
    margin-right: rem(32);

    &:last-child {
      margin-right: 0;
    }
  }
}
