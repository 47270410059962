.page-title {
  width: 100%;
}

.page-title__inner {
  display: flex;
}

.page-title__image {
  margin-right: rem(16);

  @include mobile {
    display: none;
  }
}

.page-title__cta {
  display: flex;
  justify-content: flex-end;
  margin-top: rem(40);

  @include mobile {
    margin-top: rem(24);

    .button {
      width: 100%;
    }
  }
}
