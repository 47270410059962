.footer {
  position: relative;
  margin-top: rem(100);
  background-color: $paper-white;
  border-top: 1px solid $light-grey;
  background-image: url(/assets/img/spektro.svg);
  background-repeat: no-repeat;
  background-position: calc(100% + 51px) calc(100% + 51px);
}

.footer__inner {
  padding: rem(40) 0;
}

.footer__footer {
  margin-top: rem(32);
}

.footer__info-item {
  @include desktop {
    margin-bottom: map_get($spaces, l);
  }
}

.footer__meta {
  @include list-reset;

  display: flex;
  align-items: center;
  flex-wrap: wrap;

  > li {
    margin-right: rem(24);

    &:last-child {
      margin-right: 0;
    }
  }

  @include tablet {
    > li {
      width: 100%;
      margin-bottom: map_get($spaces, s);
    }
  }
}
