// Overwrite default popper styles
.popper.info-popup {
  text-align: left;
  padding: rem(16);
  background-color: $white;
  border-color: $light-grey;
  box-shadow: 2px 2px 6px 0 rgba($black, 0.1);

  &[x-placement^=left] .popper__arrow,
  &[x-placement^=right] .popper__arrow {
    background-color: $white;
    border-top: 1px solid $light-grey;
    border-left: 1px solid $light-grey;
    border-right: none;
    border-bottom: none;
    width: rem(10);
    height: rem(10);
  }

  &[x-placement^=right] .popper__arrow {
    transform: rotate(-45deg);
    left: rem(-6);
  }

  &[x-placement^=left] .popper__arrow {
    transform: rotate(135deg);
    right: rem(-6);
  }
}

.info-popup {
  img {
    max-width: rem(420);
  }
}
