.input {
  position: relative;
  width: 100%;
}

.input__inner {
  position: relative;
  display: flex;
}

.input__float-label {
  position: absolute;
  top: 0;
  left: rem(17);
  padding-top: rem(20);
  opacity: 0.5;
  white-space: nowrap;
  color: $black;
  overflow: hidden;
  pointer-events: none;
  transform-origin: 0 50%;
  transform: translateY(-5px);
  transition: transform 0.2s ease;
}

.input__input {
  @include tx-body;

  padding: rem(20) rem(16) rem(4) rem(16);
  border-radius: 5px;
  border: 1px solid $silver;
  background-color: $white;
  width: 100%;
  color: $black;
  outline: none;

  &:focus {
    border-color: $dark-grey;
  }

  &:disabled {
    border-color: $medium-grey;
    color: $medium-grey;
  }
}

.input__info {
  z-index: 10;
  position: absolute;
  top: 50%;
  left: calc(100% + #{rem(16)});
  transform: translateY(-50%);

  @include mobile {
    display: none;
  }
}

.input__error {
  position: absolute;
  top: calc(100% + #{rem(4)});
  left: 0;
  font-size: rem(12);
  font-weight: 600;
  line-height: 1.167;
  color: $red;
  white-space: nowrap;
}

// Modifiers
.input--is-floating {
  .input__float-label {
    transform: translateY(-33%) scale(0.675);
  }
}

.input--is-readonly {
  .input__input {
    color: rgba($black, 0.5);
    cursor: default;
  }
}

.input--is-disabled {
  pointer-events: none;

  .input__float-label {
    color: $medium-grey;
    opacity: 1;
  }

  .input__input {
    color: $medium-grey !important;
  }
}

.input--has-error {
  .input__input {
    border-color: $red;
  }
}
