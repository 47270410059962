.button {
  @include btn-base;
  @include tx-body;

  position: relative;
  padding: rem(13) rem(20) rem(11);
  font-weight: bold;
  color: $white;
  border: 0;
  border-radius: 5px;
  background-color: $primary;
  transition: background-color 0.2s ease, color 0.2s ease;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: $primary-dark;
  }
}

.button__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}

.button__loader-anim {
  display: block;
  width: rem(24);
  height: rem(24);
  border-radius: 100%;
  border: 2px solid #fff;
  border-bottom-color: transparent;
  background: 0;
  animation-name: button-loader-anim;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

// Modifiers
.button--is-loading {
  pointer-events: none;

  .button__label {
    opacity: 0;
  }
}

.button--secondary {
  background-color: $white;
  color: $primary;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid $primary;
    border-radius: 5px;
    transition: background-color 0.2s ease;
  }

  &:hover {
    background-color: $white;
    color: $primary-dark;

    &::before {
      border-color: $primary-dark;
    }
  }
}
