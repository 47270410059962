.radio {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.radio__box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(20);
  height: rem(20);
  background-color: $white;
  border-radius: 100%;
  border: 1px solid $silver;
  transition: background-color 0.2s ease, border-color 0.2s ease;
}

.radio__box-dot {
  width: rem(6);
  height: rem(6);
  border-radius: 100%;
  background-color: $white;
  transition: background-color 0.2s ease;
}

.radio:hover,
.radio__input:focus + {
  .radio__box {
    border-color: $dark-grey;

    .radio__box-dot {
      background-color: $medium-grey;
    }
  }
}

.radio__input:checked + .radio__box {
  background-color: $secondary;
  border-color: $secondary;
}

.radio__input:checked:focus + .radio__box,
.radio__input:checked:hover + .radio__box {
  background-color: $secondary-dark;
  border-color: $secondary-dark;

  .radio__box-dot {
    background-color: $white;
  }
}

.radio__label {
  margin-top: rem(4);
  margin-left: rem(8);
}

// Modifiers
.radio--is-disabled {
  pointer-events: none;

  .radio__input:checked + .radio__box {
    background-color: $medium-grey;
    border-color: $medium-grey;
  }

  .radio__input + .radio__box {
    border-color: $medium-grey;
  }

  .radio__label {
    color: $medium-grey;
  }
}
