.container {
  @include grid-container;
}

.row {
  @include grid-row;
}

// Column classes
@for $i from 1 through $grid-columns {
  .col-#{$i} {
    @include grid-col($cols: $i);
  }
}

// Column centered classes
@for $i from 1 through $grid-columns {
  .col-#{$i}-c {
    @include grid-col($cols: $i, $centered: true);
  }
}

// Offset classes
@for $i from 1 through $grid-columns {
  .col-offset-#{$i} {
    @include grid-offset($cols: $i);
  }
}

// For a full width row you can combine .row and .col-12 to avoid an extra div.
.row.col-12 {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

@include desktop {
  // Column classes
  @for $i from 1 through $grid-columns {
    .col-dt-#{$i} {
      @include grid-col($cols: $i);

      // Clear any offset from possible previous centered class.
      margin-left: 0;
    }
  }

  // Column centered classes
  @for $i from 1 through $grid-columns {
    .col-dt-#{$i}-c {
      @include grid-col($cols: $i, $centered: true);
    }
  }

  // Offset classes
  @for $i from 1 through $grid-columns {
    .col-dt-offset-#{$i} {
      @include grid-offset($cols: $i);
    }
  }
}

@include tablet {
  // Column classes
  @for $i from 1 through $grid-columns {
    .col-tab-#{$i} {
      @include grid-col($cols: $i);

      // Clear any offset from possible previous centered class.
      margin-left: 0;
    }
  }

  // Column centered classes
  @for $i from 1 through $grid-columns {
    .col-tab-#{$i}-c {
      @include grid-col($cols: $i, $centered: true);
    }
  }

  // Offset classes
  @for $i from 1 through $grid-columns {
    .col-tab-offset-#{$i} {
      @include grid-offset($cols: $i);
    }
  }
}

@include tablet-portrait {
  // Column classes
  @for $i from 1 through $grid-columns {
    .col-tabp-#{$i} {
      @include grid-col($cols: $i);

      // Clear any offset from possible previous centered class.
      margin-left: 0;
    }
  }

  // Column centered classes
  @for $i from 1 through $grid-columns {
    .col-tabp-#{$i}-c {
      @include grid-col($cols: $i, $centered: true);
    }
  }

  // Offset classes
  @for $i from 1 through $grid-columns {
    .col-tabp-offset-#{$i} {
      @include grid-offset($cols: $i);
    }
  }
}

@include mobile {
  // Column classes
  @for $i from 1 through $grid-columns {
    .col-mob-#{$i} {
      @include grid-col($cols: $i);

      // Clear any offset from possible previous centered class.
      margin-left: 0;
    }
  }

  // Column centered classes
  @for $i from 1 through $grid-columns {
    .col-mob-#{$i}-c {
      @include grid-col($cols: $i, $centered: true);
    }
  }

  // Offset classes
  @for $i from 1 through $grid-columns {
    .col-mob-offset-#{$i} {
      @include grid-offset($cols: $i);
    }
  }
}
