.panel {
  display: flex;
  align-items: center;
  padding: rem(13) rem(16);
  width: 100%;
  border-radius: 5px;
  background-color: $paper-white;
  border: 1px solid $light-grey;
}

.panel__icon {
  display: flex;
  margin-right: rem(18);
  width: rem(32);
  height: rem(32);
  border-radius: 100%;
  background-color: $primary;

  .svg-icon > svg {
    fill: $white;
  }
}

.panel__text {
  margin-top: rem(6);
}
