$mq-mobile: 500px;
$mq-tablet-portrait: 750px;
$mq-tablet: 1000px;
$mq-desktop: 1220px;

@mixin mobile {
  @media (max-width: $mq-mobile) {
    @content;
  }
}

@mixin tablet-portrait {
  @media (max-width: $mq-tablet-portrait) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: $mq-tablet) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: $mq-desktop) {
    @content;
  }
}
