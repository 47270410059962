@keyframes button-loader-anim {
  0% {
    transform: rotate(0) scale(1);
  }

  50% {
    transform: rotate(180deg) scale(0.9);
  }

  100% {
    transform: rotate(360deg) scale(1);
  }
}
