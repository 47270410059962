@mixin tx-title-l {
  font-size: rem(22);
  line-height: 1.1818;
  font-weight: 700;
}

@mixin tx-title-m {
  font-size: rem(18);
  line-height: 1.3333;
  font-weight: 600;
}

@mixin tx-title-s {
  font-size: rem(16);
  line-height: 1.25;
  font-weight: 700;
}

@mixin tx-title-xs {
  font-size: rem(12);
  line-height: 1.5;
  font-weight: 600;
}

@mixin tx-body {
  font-size: rem(16);
  line-height: 1.25;
  font-weight: 400;
}

@mixin tx-body-s {
  font-size: rem(14);
  line-height: 1.28;
  font-weight: 400;
}

@mixin tx-caption {
  font-size: rem(13);
  line-height: 1.38;
  font-weight: 400;
}
