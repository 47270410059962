.reading-form__form-row {
  @include space(margin-bottom, l);
}

.reading-form__cta {
  display: flex;
  justify-content: flex-end;
}

.reading-form__radio-group {
  @include list-reset;

  display: flex;
  flex-wrap: wrap;

  > li {
    margin-right: rem(32);

    &:last-child {
      margin-right: 0;
    }
  }

  @include mobile {
    > li {
      width: 100%;
      margin-bottom: map_get($spaces, s);
    }
  }
}
