// Colors
$green: rgb(80, 175, 45);
$green-light: rgb(124, 195, 97);
$green-dark: rgb(60, 131, 34);
$blue: rgb(0, 150, 220);
$blue-dark: rgb(22, 107, 146);
$yellow-light: rgb(255, 243, 178);
$yellow-dark: rgb(255, 215, 0);
$white: rgb(255, 255, 255);
$paper-white: rgb(250, 250, 250);
$cardboard-grey: rgb(240, 240, 240);
$light-grey: rgb(226, 226, 225);
$medium-grey: rgb(221, 221, 221);
$grey: rgb(187, 187, 187);
$silver: rgb(153, 153, 153);
$dark-grey: rgb(64, 64, 64);
$black-grey: rgb(51, 51, 51);
$green2: rgb(0, 179, 125);
$red: rgb(225, 17, 78);

// Color aliases
$primary: $green;
$primary-light: $green-light;
$primary-dark: $green-dark;

$secondary: $blue;
$secondary-dark: $blue-dark;
$accent: $yellow-light;
$accent-dark: $yellow-dark;

$black: $black-grey;

// Settings
$base-font-size: 16px; // This value is needed for correct rem calculations. Should be like browser default, 16px.
$base-line-height: 1.25;
$body-font: "Glober", Helvetica Neue, Arial, sans-serif;
$body-font-color: $black;

// Grid
$site-width: 1188px;
$gutter: 30px;
$gutter-mobile: 20px;
$side-padding: 16px;
$grid-columns: 12;
