$spaces: (
  xxl: 80px,
  xl: 40px,
  l: 32px,
  m: 24px,
  s: 16px,
  xs: 8px
);

@mixin space($prop, $size, $negative: false) {
  #{$prop}: rem(map-get($spaces, $size) * (if($negative, -1, 1)));

  //@include tablet {
  //  #{$prop}: rem(map-get($spaces-tablet, $size) * (if($negative, -1, 1)));
  //}
  //
  //// Use mobile spacings already on tablet-portrait
  //@include tablet-portrait {
  //  #{$prop}: rem(map-get($spaces-mobile, $size) * (if($negative, -1, 1)));
  //}
}
