// Glober regular
@font-face {
  font-family: "Glober";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/2D9033_9_0.eot"); /* IE9 Compat Modes */
  src:
    local("Glober Regular"),
    url("../assets/fonts/2D9033_9_0.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/2D9033_9_0.woff2") format("woff2"),
    url("../assets/fonts/2D9033_9_0.woff") format("woff"),
    url("../assets/fonts/2D9033_9_0.ttf") format("truetype") /* Safari, Android, iOS */
}

// Glober semibold
@font-face {
  font-family: "Glober";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/2D9033_C_0.eot"); /* IE9 Compat Modes */
  src:
    local("Glober SemiBold"),
    url("../assets/fonts/2D9033_C_0.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/2D9033_C_0.woff2") format("woff2"),
    url("../assets/fonts/2D9033_C_0.woff") format("woff"),
    url("../assets/fonts/2D9033_C_0.ttf") format("truetype") /* Safari, Android, iOS */
}

// Glober bold
@font-face {
  font-family: "Glober";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/2D9033_2_0.eot"); /* IE9 Compat Modes */
  src:
    local("Glober Bold"),
    url("../assets/fonts/2D9033_2_0.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/2D9033_2_0.woff2") format("woff2"),
    url("../assets/fonts/2D9033_2_0.woff") format("woff"),
    url("../assets/fonts/2D9033_2_0.ttf") format("truetype") /* Safari, Android, iOS */
}
